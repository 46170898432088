import client from "@/utils/api/client";
import { SuggestKeywordResponse } from "@/web-client/api";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

const useSuggestKeywords = (keyword: string): SuggestKeywordResponse[] => {
  const [debouncedKeyword] = useDebounceValue(keyword, 500);
  const [suggestKeywords, setSuggestKeywords] = useState<
    SuggestKeywordResponse[]
  >([]);

  useEffect(() => {
    if (!keyword) {
      setSuggestKeywords([]);

      return;
    }

    const fetch = async () => {
      const { data } = await client.suggestKeywordsGet({ keyword });

      setSuggestKeywords(data);
    };

    fetch();
  }, [debouncedKeyword]);

  return suggestKeywords;
};

export default useSuggestKeywords;
