import Badge from "@/components/Badge";
import useCart from "@/hooks/useCart";
import IconCart from "@/assets/imgs/svg/icon_cart.svg";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { useRouter } from "next/router";
import { getReasonText } from "@/utils/getReasonText";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { theme } from "tailwind.config";
import { useIsClient } from "usehooks-ts";

const CartButtonInTheHeader = () => {
  const router = useRouter();
  const { quantity } = useCart();
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: () => router.push("/cart"),
    message: getReasonText(RedirectReason.ShowInCart),
    requiredIsPro: true,
  });

  // カートの中の数字を表示するため、サーバーサイドと結果が異なるためhydration errorが発生する
  // そのため、クライアントサイドでのみ表示するようにする
  const isClient = useIsClient();

  if (!isClient) return null;

  return (
    <>
      <button
        type="button"
        onClick={executeAction}
        aria-label="カートに移動する"
      >
        <Badge badgeContent={quantity}>
          <IconCart width={24} height={24} fill={theme.colors.gray[950]} />
        </Badge>
      </button>

      {SignInModalComponent}
    </>
  );
};
export default CartButtonInTheHeader;
