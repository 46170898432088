import { RootState } from "@/stores/rootReducer";
import useDisclosure from "@/hooks/useDisclosure";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import Logo from "@/assets/imgs/svg/logo-default.svg";
import BasicButton, { BasicButtonStyle } from "@/components/BasicButton";
import HeaderNavigationItem from "@/components/HeaderNavigationItem";
import RenderIfAuth from "@/components/RenderIfAuth";
import RenderIfNotAuth from "@/components/RenderIfNotAuth";
import SearchBar from "@/components/SearchBar";
import { theme } from "tailwind.config";
import NotificationPopover from "@/features/notification/components/NotificationPopover";
import IconNotification from "@/assets/imgs/svg/icon_notification.svg";
import ActiveIconWrapper from "@/components/ActiveIconWrapper";
import { RedirectReason } from "@/domain/values/RedirectReason";
import useModalLimitFunction from "@/features/user/hooks/useModalLimitFunction";
import useUserStatus from "@/features/user/hooks/useUserStatus";
import { useSignInRequiredAction } from "@/features/user_authentication";
import Button from "@/components/Button";
import { getReasonText } from "@/utils/getReasonText";
import MyMenu from "@/components/MyMenu";
import Marquee from "react-fast-marquee";
import { AWARD_URL } from "@/config/env";
import Image from "next/image";
import clsx from "clsx";
import Divider from "@/components/Divider";
import CartButtonInTheHeader from "@/components/CartButtonInTheHeader";

const DynamicModalSearch = dynamic(() => import("./ModalSearch"));
const DynamicDialogJoinTeam = dynamic(
  () => import("@/features/team/components/DialogJoinTeam"),
);

const TheHeader: FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const router = useRouter();
  const { hasTeam, isDesigner } = useUserStatus();

  const { open: openModalLimitFunction } = useModalLimitFunction();

  const { isOpen, openHandler, closeHandler } = useDisclosure();

  const {
    isOpen: isDialogJoinTeamOpen,
    openHandler: openDialogJoinTeam,
    closeHandler: closeDialogJoinTeam,
  } = useDisclosure();

  const { executeAction: clickPostProjectBtnHandler, SignInModalComponent } =
    useSignInRequiredAction({
      action: () => {
        if (!isDesigner) {
          openModalLimitFunction();

          return;
        }

        if (!hasTeam) {
          openDialogJoinTeam();

          return;
        }

        router.push(`/projects/new`);
      },
      message: getReasonText(RedirectReason.CreateProject),
    });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <header className="TheHeader">
      <div className="relative bg-black py-12">
        <Marquee autoFill speed={12}>
          <div className="px-12">
            <Image
              width={577}
              height={17}
              src="/imgs/awards/tecture-award-2024/marquee.png"
              alt="TECTURE AWARD 受賞者発表"
              unoptimized
            />
          </div>
        </Marquee>
        <Link
          className="absolute inset-0 z-10"
          href={AWARD_URL}
          target="_blank"
        />
      </div>
      <div className="laptop:pt-12 laptop:px-12">
        <div className="laptop:space-y-6">
          <div className="flex items-center justify-between laptop:justify-start laptop:px-0 laptop:pt-0 laptop:space-x-12 pt-12 px-12">
            <div className="leading-none flex items-center space-x-12 laptop:space-x-24">
              <Link
                href="/"
                prefetch={false}
                className="TheHeader__logo SVGWrapper"
              >
                <Logo />
              </Link>
            </div>

            <div className="TheHeader__search hidden laptop:flex">
              <div className="flex-1 text-2xs laptop:text-sm">
                <SearchBar
                  placeholder="建物や家具の名前で検索してみよう。"
                  backgroundColor={theme.colors.gray[100]}
                />
              </div>
            </div>

            <nav className="TheHeader__nav leading-none space-x-12 laptop:space-x-0">
              <div className="laptop:hidden">
                <button onClick={openHandler}>
                  <i className="icon-Search text-xl"></i>
                </button>
              </div>

              <ul className="space-x-12 flex items-center">
                <li>
                  <CartButtonInTheHeader />
                </li>

                <RenderIfNotAuth>
                  <>
                    <li>
                      <Link href="/signin" prefetch={false}>
                        ログイン
                      </Link>
                    </li>
                    <li>
                      <Link href="/signup" prefetch={false}>
                        新規登録
                      </Link>
                    </li>

                    <li className="hidden laptop:block">
                      <BasicButton
                        style={BasicButtonStyle.UniqueAction}
                        label={"プロジェクト投稿"}
                        onClick={clickPostProjectBtnHandler}
                      />
                    </li>
                  </>
                </RenderIfNotAuth>

                <RenderIfAuth>
                  <>
                    <li>
                      <NotificationPopover
                        isOpen={isPopoverOpen}
                        close={() => setIsPopoverOpen(false)}
                      >
                        <button
                          onClick={() => setIsPopoverOpen((prev) => !prev)}
                        >
                          <ActiveIconWrapper
                            active={user ? user.has_unread_notification : false}
                          >
                            <IconNotification
                              width={24}
                              height={24}
                              fill={theme.colors.gray[950]}
                            />
                          </ActiveIconWrapper>
                        </button>
                      </NotificationPopover>
                    </li>
                    <li>
                      <MyMenu />
                    </li>
                    <li className="hidden laptop:block">
                      <Button size="small" onClick={clickPostProjectBtnHandler}>
                        プロジェクト投稿
                      </Button>
                    </li>
                  </>
                </RenderIfAuth>
              </ul>
            </nav>
          </div>

          <div
            className={clsx(
              "flex items-center overflow-auto whitespace-nowrap text-xs space-x-16  font-bold",
              "px-12 py-16",
              "laptop:space-x-24 laptop:text-sm laptop:px-24 laptop:justify-between",
            )}
          >
            <div className="flex items-center whitespace-nowrap gap-16 laptop:gap-24">
              <HeaderNavigationItem href="/architectures">
                建築を探す
              </HeaderNavigationItem>
              <HeaderNavigationItem href="/building_materials">
                建材を探す
              </HeaderNavigationItem>
              <HeaderNavigationItem href="/interiors">
                家具を探す
              </HeaderNavigationItem>
              <HeaderNavigationItem href="/makers">
                メーカーを探す
              </HeaderNavigationItem>

              <RenderIfAuth>
                <Divider direction="vertical" className="!h-[14px]" />

                <HeaderNavigationItem href="/followings">
                  フォロー中
                </HeaderNavigationItem>
              </RenderIfAuth>
            </div>

            <div className="flex whitespace-nowrap space-x-16 laptop:space-x-24 pl-12 laptop:pl-24">
              <HeaderNavigationItem href="/about">
                TECTUREとは？
              </HeaderNavigationItem>

              <HeaderNavigationItem
                href="https://official.tecture.jp/customer-support/user"
                target="_blank"
              >
                サービスの使い方
              </HeaderNavigationItem>
            </div>
          </div>
        </div>

        {isOpen && <DynamicModalSearch closeHandler={closeHandler} />}
        <DynamicDialogJoinTeam
          isOpen={isDialogJoinTeamOpen}
          onClose={closeDialogJoinTeam}
        />
      </div>

      {SignInModalComponent}
    </header>
  );
};

export default TheHeader;
